import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

const data = [
  {
    name: "Work of art",
    valueA: 400,
    valueB: 300,
    valueC: 200,
  },
  {
    name: "Price",
    valueA: 800,
    valueB: 600,
    valueC: 400,
  },
  {
    name: "Person",
    valueA: 300,
    valueB: 500,
    valueC: 700,
  },
  {
    name: "Other",
    valueA: 100,
    valueB: 700,
    valueC: 500,
  },
  {
    name: "OtherA",
    valueA: 300,
    valueB: 100,
    valueC: 400,
  },
  {
    name: "New",
    valueA: 300,
    valueB: 450,
    valueC: 320,
  },
  {
    name: "Work",
    valueA: 300,
    valueB: 100,
    valueC: 200,
  },
  // Add more data as needed
];

const legendPayload = [
  { value: "Positive", type: "rect", id: "legend-valueA", color: "#8884d8" },
  { value: "Negative", type: "rect", id: "legend-valueB", color: "#82ca9d" },
  { value: "Neutral", type: "rect", id: "legend-valueC", color: "#ffc658" },
];

const VerticleBarChart = () => {
  return (
    <BarChart
      width={500}
      height={500}
      layout="vertical" // Set the layout to 'vertical'
      data={data}
      margin={{
        top: 20,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <YAxis type="category" dataKey="name" /> {/* Change YAxis to XAxis */}
      <XAxis type="number" /> {/* Change XAxis to YAxis */}
      <Tooltip />
      <Legend payload={legendPayload} />
      <Bar dataKey="valueA" stackId="a" fill="#8884d8" values="Positive" />
      <Bar dataKey="valueB" stackId="a" fill="#82ca9d" />
      <Bar dataKey="valueC" stackId="a" fill="#ffc658" />
    </BarChart>
  );
};

export default VerticleBarChart;
