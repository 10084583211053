import React from "react";
import ReactWordcloud from "react-wordcloud";
import { wordCloudData } from "../utils";

const WordCloud = () => {
  const options = {
    colors: ["#1f77b4", "#ff7f0e", "#2ca02c", "#d62728", "#9467bd", "#8c564b"],
    enableTooltip: true,
    deterministic: false,
    fontFamily: "impact",
    fontSizes: [5, 60],
    fontStyle: "normal",
    fontWeight: "normal",
    padding: 1,
    rotations: 3,
    rotationAngles: [-60, 0],
    scale: "sqrt",
    spiral: "archimedean",
    transitionDuration: 1000,
  };
  // const options = {
  //   rotations: 2,
  //   rotationAngles: [-90, 0],
  // };
  const size = [700, 700];
  return (
    <div>
      <h2>Analytics Page</h2>
      <ReactWordcloud words={wordCloudData} size={size} options={options} />
    </div>
  );
};

export default WordCloud;
