export const wordCloudData = [
  { text: "godzilla", value: 16 },
  { text: "remake", value: 14 },
  { text: "amazing", value: 6 },
  { text: "enjoyed", value: 24 },
  { text: "theater", value: 15 },
  { text: "enjoy", value: 8 },
  { text: "home", value: 8 },
  { text: "outstanding", value: 3 },
  { text: "bluray", value: 12 },
  { text: "movie", value: 22 },
  { text: "kind", value: 7 },
  { text: "loved", value: 22 },
  { text: "much", value: 28 },
  { text: "action", value: 26 },
  { text: "suspense", value: 2 },
  { text: "wow", value: 2 },
  { text: "ive", value: 9 },
  { text: "watched", value: 9 },
  { text: "every", value: 7 },
  { text: "ever", value: 5 },
  { text: "favorite", value: 5 },
  { text: "far", value: 3 },
  { text: "looked", value: 4 },
  { text: "especially", value: 3 },
  { text: "nice", value: 6 },
  { text: "4k", value: 3 },
  { text: "tv", value: 7 },
  { text: "also", value: 7 },
  { text: "bought", value: 11 },
  { text: "best", value: 22 },
  { text: "buy", value: 14 },
  { text: "usually", value: 3 },
  { text: "love", value: 26 },
  { text: "type", value: 3 },
  { text: "one", value: 35 },
  { text: "boring", value: 3 },
  { text: "husband", value: 4 },
  { text: "kid", value: 9 },
  { text: "liked", value: 14 },
  { text: "though", value: 8 },
  { text: "great", value: 102 },
  { text: "new", value: 15 },
  { text: "take", value: 14 },
  { text: "like", value: 40 },
  { text: "took", value: 7 },
  { text: "approach", value: 2 },
  { text: "instead", value: 5 },
  { text: "got", value: 9 },
  { text: "90", value: 4 },
  { text: "special", value: 16 },
  { text: "effect", value: 30 },
  { text: "way", value: 17 },
  { text: "watch", value: 29 },
  { text: "different", value: 5 },
  { text: "old", value: 27 },
  { text: "think", value: 7 },
  { text: "yet", value: 3 },
  { text: "really", value: 29 },
  { text: "awesome", value: 22 },
  { text: "franchise", value: 12 },
  { text: "acting", value: 12 },
  { text: "well", value: 20 },
  { text: "classic", value: 21 },
  { text: "bryan", value: 6 },
  { text: "cranston", value: 8 },
  { text: "fantastic", value: 6 },
  { text: "always", value: 8 },
  { text: "saw", value: 11 },
  { text: "purchase", value: 7 },
  { text: "collection", value: 10 },
  { text: "since", value: 6 },
  { text: "enough", value: 15 },
  { text: "reward", value: 2 },
  { text: "coupon", value: 2 },
  { text: "item", value: 3 },
  { text: "without", value: 7 },
  { text: "money", value: 3 },
  { text: "store", value: 3 },
  { text: "order", value: 2 },
  { text: "original", value: 23 },
  { text: "disaster", value: 3 },
  { text: "flick", value: 6 },
  { text: "updated", value: 2 },
  { text: "look", value: 14 },
  { text: "3d", value: 32 },
  { text: "good", value: 63 },
  { text: "addition", value: 4 },
  { text: "fan", value: 30 },
  { text: "wrong", value: 4 },
  { text: "actor", value: 2 },
  { text: "youll", value: 2 },
  { text: "know", value: 7 },
  { text: "im", value: 6 },
  { text: "monster", value: 56 },
  { text: "delivers", value: 2 },
  { text: "usual", value: 2 },
  { text: "fun", value: 7 },
  { text: "family", value: 7 },
  { text: "night", value: 4 },
  { text: "didnt", value: 8 },
  { text: "godzillas", value: 2 },
  { text: "roar", value: 7 },
  { text: "graphic", value: 4 },
  { text: "ok", value: 7 },
  { text: "whole", value: 5 },
  { text: "story", value: 29 },
  { text: "line", value: 7 },
  { text: "weak", value: 3 },
  { text: "forever", value: 2 },
  { text: "build", value: 3 },
  { text: "character", value: 11 },
  { text: "scene", value: 8 },
  { text: "felt", value: 7 },
  { text: "thought", value: 12 },
  { text: "little", value: 9 },
  { text: "someone", value: 4 },
  { text: "would", value: 29 },
  { text: "lizard", value: 2 },
  { text: "city", value: 3 },
  { text: "long", value: 6 },
  { text: "term", value: 3 },
  { text: "people", value: 8 },
  { text: "wont", value: 2 },
  { text: "go", value: 9 },
  { text: "thru", value: 2 },
  { text: "end", value: 5 },
  { text: "must", value: 10 },
  { text: "die", value: 2 },
  { text: "not", value: 51 },
  { text: "huge", value: 4 },
  { text: "happy", value: 3 },
  { text: "worth", value: 13 },
  { text: "wish", value: 11 },
  { text: "price", value: 10 },
  { text: "true", value: 6 },
  { text: "turned", value: 2 },
  { text: "modern", value: 5 },
  { text: "time", value: 33 },
  { text: "done", value: 9 },
  { text: "bit", value: 7 },
  { text: "till", value: 2 },
  { text: "fight", value: 5 },
  { text: "keep", value: 9 },
  { text: "everyone", value: 5 },
  { text: "wanting", value: 2 },
  { text: "see", value: 26 },
  { text: "big", value: 15 },
  { text: "give", value: 2 },
  { text: "5", value: 2 },
  { text: "made", value: 23 },
  { text: "school", value: 5 },
  { text: "waiting", value: 7 },
  { text: "sequel", value: 10 },
  { text: "version", value: 18 },
  { text: "100", value: 2 },
  { text: "better", value: 37 },
  { text: "first", value: 9 },
  { text: "american", value: 5 },
  { text: "get", value: 24 },
  { text: "son", value: 4 },
  { text: "missing", value: 4 },
  { text: "forget", value: 2 },
  { text: "film", value: 37 },
  { text: "needed", value: 2 },
  { text: "brian", value: 3 },
  { text: "doe", value: 6 },
  { text: "job", value: 5 },
  { text: "brilliant", value: 2 },
  { text: "seen", value: 15 },
  { text: "several", value: 3 },
  { text: "already", value: 4 },
  { text: "taken", value: 2 },
  { text: "still", value: 14 },
  { text: "entertained", value: 4 },
  { text: "edge", value: 3 },
  { text: "seat", value: 3 },
  { text: "entire", value: 2 },
  { text: "forward", value: 2 },
  { text: "seeing", value: 5 },
  { text: "series", value: 10 },
  { text: "start", value: 6 },
  { text: "entertaining", value: 3 },
  { text: "1998", value: 7 },
  { text: "recommended", value: 3 },
  { text: "anyone", value: 3 },
  { text: "half", value: 2 },
  { text: "slow", value: 10 },
  { text: "going", value: 8 },
  { text: "pick", value: 3 },
  { text: "interesting", value: 6 },
  { text: "glad", value: 5 },
  { text: "human", value: 11 },
  { text: "another", value: 3 },
  { text: "adaptation", value: 2 },
  { text: "beam", value: 2 },
  { text: "put", value: 4 },
  { text: "together", value: 3 },
  { text: "blue", value: 3 },
  { text: "digital", value: 5 },
  { text: "blu", value: 8 },
  { text: "ray", value: 10 },
  { text: "excellent", value: 12 },
  { text: "guy", value: 6 },
  { text: "even", value: 8 },
  { text: "expectation", value: 3 },
  { text: "kept", value: 4 },
  { text: "interest", value: 5 },
  { text: "point", value: 5 },
  { text: "care", value: 2 },
  { text: "plot", value: 7 },
  { text: "focus", value: 3 },
  { text: "expected", value: 6 },
  { text: "lot", value: 21 },
  { text: "waited", value: 2 },
  { text: "finally", value: 6 },
  { text: "disappointed", value: 5 },
  { text: "ill", value: 2 },
  { text: "watching", value: 15 },
  { text: "isnt", value: 3 },
  { text: "freaking", value: 2 },
  { text: "overall", value: 14 },
  { text: "quality", value: 10 },
  { text: "jaw", value: 2 },
  { text: "show", value: 7 },
  { text: "second", value: 3 },
  { text: "day", value: 7 },
  { text: "came", value: 6 },
  { text: "audio", value: 4 },
  { text: "nothing", value: 3 },
  { text: "image", value: 2 },
  { text: "back", value: 13 },
  { text: "make", value: 11 },
  { text: "come", value: 7 },
  { text: "fast", value: 3 },
  { text: "cg", value: 2 },
  { text: "used", value: 3 },
  { text: "heard", value: 2 },
  { text: "bad", value: 10 },
  { text: "recent", value: 2 },
  { text: "entry", value: 3 },
  { text: "list", value: 2 },
  { text: "flaw", value: 2 },
  { text: "say", value: 12 },
  { text: "yes", value: 2 },
  { text: "cant", value: 12 },
  { text: "beat", value: 2 },
  { text: "fact", value: 2 },
  { text: "credit", value: 4 },
  { text: "10", value: 2 },
  { text: "minute", value: 2 },
  { text: "kaiju", value: 3 },
  { text: "thing", value: 6 },
  { text: "mutos", value: 3 },
  { text: "throughout", value: 2 },
  { text: "perspective", value: 2 },
  { text: "arent", value: 3 },
  { text: "part", value: 9 },
  { text: "picture", value: 4 },
  { text: "need", value: 6 },
  { text: "king", value: 8 },
  { text: "wait", value: 11 },
  { text: "2", value: 6 },
  { text: "wasnt", value: 6 },
  { text: "hope", value: 5 },
  { text: "others", value: 2 },
  { text: "couldnt", value: 4 },
  { text: "matthew", value: 2 },
  { text: "real", value: 6 },
  { text: "however", value: 4 },
  { text: "screen", value: 12 },
  { text: "video", value: 4 },
  { text: "decent", value: 6 },
  { text: "year", value: 6 },
  { text: "word", value: 2 },
  { text: "gift", value: 5 },
  { text: "dad", value: 3 },
  { text: "reboot", value: 5 },
  { text: "maybe", value: 3 },
  { text: "fighting", value: 7 },
  { text: "surprised", value: 4 },
  { text: "place", value: 2 },
  { text: "dark", value: 3 },
  { text: "exception", value: 2 },
  { text: "feel", value: 4 },
  { text: "could", value: 5 },
  { text: "actually", value: 6 },
  { text: "moment", value: 5 },
  { text: "audience", value: 2 },
  { text: "hard", value: 3 },
  { text: "sure", value: 3 },
  { text: "check", value: 3 },
  { text: "pretty", value: 9 },
  { text: "showed", value: 3 },
  { text: "style", value: 3 },
  { text: "try", value: 2 },
  { text: "title", value: 3 },
  { text: "full", value: 2 },
  { text: "sit", value: 2 },
  { text: "held", value: 2 },
  { text: "age", value: 4 },
  { text: "pacific", value: 2 },
  { text: "rim", value: 2 },
  { text: "giant", value: 7 },
  { text: "scale", value: 2 },
  { text: "everything", value: 5 },
  { text: "japanese", value: 8 },
  { text: "grew", value: 4 },
  { text: "close", value: 2 },
  { text: "cgi", value: 2 },
  { text: "previous", value: 2 },
  { text: "predictable", value: 2 },
  { text: "recommend", value: 14 },
  { text: "packed", value: 2 },
  { text: "adventure", value: 2 },
  { text: "heart", value: 3 },
  { text: "last", value: 11 },
  { text: "wanted", value: 5 },
  { text: "satisfying", value: 2 },
  { text: "lead", value: 2 },
  { text: "brawl", value: 3 },
  { text: "getting", value: 2 },
  { text: "dont", value: 8 },
  { text: "sci", value: 2 },
  { text: "fi", value: 2 },
  { text: "want", value: 3 },
  { text: "v", value: 3 },
  { text: "went", value: 3 },
  { text: "scary", value: 2 },
  { text: "excited", value: 4 },
  { text: "mixed", value: 2 },
  { text: "review", value: 4 },
  { text: "kinda", value: 2 },
  { text: "friend", value: 2 },
  { text: "nephew", value: 2 },
  { text: "able", value: 3 },
  { text: "incredible", value: 2 },
  { text: "brought", value: 4 },
  { text: "surround", value: 3 },
  { text: "sound", value: 11 },
  { text: "creature", value: 3 },
  { text: "tell", value: 2 },
  { text: "ended", value: 2 },
  { text: "disappointment", value: 2 },
  { text: "appearance", value: 3 },
  { text: "purchased", value: 2 },
  { text: "along", value: 2 },
  { text: "destruction", value: 2 },
  { text: "highly", value: 5 },
  { text: "cool", value: 2 },
  { text: "something", value: 5 },
  { text: "expect", value: 5 },
  { text: "finish", value: 2 },
  { text: "rent", value: 2 },
  { text: "simply", value: 4 },
  { text: "kong", value: 2 },
  { text: "hollywood", value: 2 },
  { text: "taking", value: 2 },
  { text: "attempt", value: 2 },
  { text: "hey", value: 2 },
  { text: "help", value: 2 },
  { text: "star", value: 4 },
  { text: "worst", value: 2 },
  { text: "man", value: 4 },
  { text: "life", value: 5 },
  { text: "epic", value: 4 },
  { text: "definitely", value: 8 },
  { text: "telling", value: 4 },
  { text: "many", value: 9 },
  { text: "again", value: 4 },
  { text: "level", value: 2 },
  { text: "plenty", value: 3 },
  { text: "may", value: 2 },
  { text: "side", value: 2 },
  { text: "wife", value: 2 },
  { text: "find", value: 2 },
  { text: "blast", value: 2 },
  { text: "return", value: 3 },
  { text: "update", value: 2 },
  { text: "truly", value: 3 },
  { text: "stellar", value: 2 },
  { text: "solid", value: 3 },
  { text: "easy", value: 3 },
  { text: "complaint", value: 2 },
  { text: "extra", value: 2 },
  { text: "feature", value: 2 },
  { text: "save", value: 2 },
  { text: "visual", value: 3 },
  { text: "opening", value: 2 },
  { text: "moviei", value: 2 },
  { text: "g", value: 2 },
  { text: "couple", value: 2 },
  { text: "started", value: 2 },
  { text: "id", value: 3 },
  { text: "remember", value: 2 },
  { text: "opinion", value: 3 },
  { text: "camera", value: 2 },
  { text: "work", value: 2 },
  { text: "left", value: 3 },
  { text: "storyline", value: 5 },
  { text: "experience", value: 2 },
  { text: "child", value: 5 },
  { text: "black", value: 4 },
  { text: "yr", value: 2 },
  { text: "gone", value: 2 },
  { text: "moving", value: 2 },
  { text: "absolutely", value: 3 },
  { text: "childhood", value: 2 },
  { text: "comic", value: 2 },
  { text: "kool", value: 2 },
  { text: "2014", value: 2 },
  { text: "hand", value: 4 },
  { text: "coming", value: 4 },
  { text: "oh", value: 2 },
  { text: "air", value: 2 },
  { text: "featuring", value: 2 },
  { text: "showing", value: 2 },
  { text: "fin", value: 2 },
  { text: "water", value: 3 },
  { text: "sale", value: 4 },
  { text: "exciting", value: 2 },
  { text: "never", value: 5 },
  { text: "twice", value: 2 },
  { text: "friday", value: 3 },
  { text: "dvd", value: 2 },
  { text: "copy", value: 2 },
  { text: "popular", value: 2 },
  { text: "twist", value: 2 },
  { text: "perfect", value: 4 },
  { text: "follows", value: 2 },
  { text: "format", value: 3 },
  { text: "perfectly", value: 2 },
  { text: "unique", value: 2 },
  { text: "staple", value: 2 },
  { text: "service", value: 2 },
  { text: "crowd", value: 4 },
  { text: "director", value: 3 },
  { text: "captured", value: 2 },
  { text: "soul", value: 2 },
  { text: "idea", value: 2 },
  { text: "hate", value: 2 },
  { text: "hear", value: 2 },
  { text: "whether", value: 2 },
  { text: "heisei", value: 2 },
  { text: "millennium", value: 2 },
  { text: "spoilerson", value: 2 },
  { text: "likely", value: 2 },
  { text: "predecessor", value: 2 },
  { text: "philosophical", value: 2 },
  { text: "question", value: 3 },
  { text: "control", value: 2 },
  { text: "nature", value: 2 },
  { text: "strap", value: 2 },
  { text: "reason", value: 3 },
  { text: "alone", value: 2 },
  { text: "greatly", value: 2 },
  { text: "describe", value: 2 },
  { text: "art", value: 3 },
  { text: "existing", value: 2 },
  { text: "base", value: 2 },
  { text: "summer", value: 3 },
  { text: "blockbuster", value: 2 },
  { text: "gojira", value: 2 },
  { text: "bringing", value: 2 },
  { text: "player", value: 3 },
  { text: "spirit", value: 3 },
  { text: "poor", value: 2 },
  { text: "memory", value: 2 },
  { text: "almost", value: 2 },
  { text: "brings", value: 2 },
  { text: "seem", value: 2 },
  { text: "issue", value: 2 },
  { text: "development", value: 2 },
  { text: "next", value: 2 },
  { text: "edward", value: 2 },
];

export const barChartData = [
  {
    name: "Page A",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Page B",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Page C",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Page D",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Page E",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Page F",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Page G",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];
