import "./App.css";
import AreaPercentageCart from "./components/AreaPercentageCart";
import ClusterBubbleChart from "./components/ClusterBubbleChart";
import VerticalBarChart from "./components/VerticalBarChart";
import WordCloud from "./components/WordCloud";

function App() {
  return (
    <div className="App">
      <WordCloud />
      <br />
      <VerticalBarChart />
      <br />
      <AreaPercentageCart />
      <br />
      <ClusterBubbleChart />
    </div>
  );
}

export default App;
